import React from "react"
import styled from "styled-components"
import axios from "axios"
import { navigate } from "gatsby"
import { Checkbox, HelpText } from "../Section"
import {CustomValidationMessage, RequiredTriangle, FormTitle, FormRow, FormCol, ControlLabel, Field } from "../../components/FormStyle"
import {ExploreMore} from "../../components/Section"
import PrimaryButton from "../../components/Button/PrimaryButton"

const Form = styled.form`
  background: #ffffff;
  border: 1px solid #98dfff;
  box-shadow: 0px 15px 45px rgba(152, 223, 225, 0.2);
  border-radius: 10px;
  padding: 30px 20px;
  @media (min-width:768px) {
    padding: 30px;
  }
`

class RightForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      phone_no: "",
      zipcode: "",
      comment: "",
      agreeViaSms: true,
      fNameRequire: false,
      lNameRequire: false,
      emailRequire: false,
      phoneRequire: false,
      zipcodeRequire: false,
      commentRequire: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      const searchParams = new URLSearchParams(window.location.search)
      if (
        searchParams.get("utm_source") != "" &&
        searchParams.get("utm_source") != null
      ) {
        sessionStorage.setItem("utm_source", searchParams.get("utm_source"))
        sessionStorage.setItem("utm_medium", searchParams.get("utm_medium"))
        sessionStorage.setItem("utm_campaign", searchParams.get("utm_campaign"))
        sessionStorage.setItem("utm_term", searchParams.get("utm_term"))
        sessionStorage.setItem("utm_content", searchParams.get("utm_content"))
      }
    }
  }
  // Use the submitted data to set the state
  handleChange(event) {
    const { id, value } = event.target
    if (id !== "agreeViaSms") {
      this.setState({
        [id]: value,
      })
    } else {
      this.setState({
        [id]: event.target.checked,
      })
    }
    if (id === "first_name") {
      this.setState({
        fNameRequire: false,
      })
    }
    if (id === "last_name") {
      this.setState({
        lNameRequire: false,
      })
    }
    if (id === "email") {
      this.setState({
        emailRequire: false,
      })
    }
    if (id === "phone_no") {
      this.setState({
        phoneRequire: false,
      })
    }
    if (id === "zipcode") {
      this.setState({
        zipcodeRequire: false,
      })
    }
    if (id === "comment") {
      this.setState({
        commentRequire: false,
      })
    }
    if (id === "agreeViaSms") {
      this.setState({
        agreeViaSmsRequire: false,
      })
    }
  }
  handleSubmit = event => {
    let utm_source_val = "Website"
    let utm_medium_val = ""
    let utm_campaign_val = ""
    let utm_term_val = ""
    let utm_content_val = ""
    if (
      sessionStorage.getItem("utm_source") != "" &&
      sessionStorage.getItem("utm_source") != null
    ) {
      utm_source_val = sessionStorage.getItem("utm_source")
      utm_medium_val = sessionStorage.getItem("utm_medium")
      utm_campaign_val = sessionStorage.getItem("utm_campaign")
      utm_term_val = sessionStorage.getItem("utm_term")
      utm_content_val = sessionStorage.getItem("utm_content")
    }
    const { location, formName } = this.props
    event.preventDefault()
    if (this.state.first_name === "") {
      this.setState({
        fNameRequire: true,
      })
    } else if (this.state.last_name === "") {
      this.setState({
        lNameRequire: true,
      })
    } else if (this.state.email === "") {
      this.setState({
        emailRequire: true,
      })
    } else if (this.state.phone_no === "") {
      this.setState({
        phoneRequire: true,
      })
    } else if (this.state.zipcode === "") {
      this.setState({
        zipcodeRequire: true,
      })
    } else if (this.state.comment === "") {
      this.setState({
        commentRequire: true,
      })
    } else {
      const data = {
        source: location.origin,
        form: formName,
        page: location.pathname,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone_no: this.state.phone_no,
        zipcode: this.state.zipcode,
        comment: this.state.comment + ", SMS consent : " + this.state.agreeViaSms,
        agreeViaSms: this.state.agreeViaSms,
        utm_medium: utm_medium_val,
        utm_term: utm_term_val,
        utm_campaign: utm_campaign_val,
        utm_content: utm_content_val,
        lead_source: utm_source_val,
      }
      axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/create-lead-wordpress",
        data: data,
        headers: {
          Authorization:
            "Bearer " +
            "13IMU4ZLF9WyrjRj6JuTfVRwGswQVmfBpO39w7bYvaFT93nbcTKE8PnUBv4bWMrM",
        },
      })
        .then(res => {
          if (typeof dataLayer !== "undefined") {
            window.dataLayer.push({
              event: "form_submit",
              formName: "website_forms",
              enhanced_conversion_data: {
                email: this.state.email,
                phone_number: this.state.phone_no,
              },
            })
          }
          this.setState({
            first_name: "",
            last_name: "",
            email: "",
            phone_no: "",
            zipcode: "",
            comment: "",
            agreeViaSms: false,
          })
          navigate("/thank-you-quick-contact/")
        })
        .catch(error => {
          alert(error)
        })
    }
  }
  render() {
    const { title } = this.props
    return (
      <Form id="rightform" onSubmit={this.handleSubmit}>
        <FormTitle className="h3">{title}</FormTitle>
        <FormRow>
          <FormCol>
            <ControlLabel>First Name</ControlLabel>
            <Field>
              <input
                type="text"
                className="form-control"
                placeholder="Please Enter Your First Name"
                id="first_name"
                onChange={this.handleChange}
                pattern="[A-Za-z\s]+$"
                required
              />
              <CustomValidationMessage display={this.state.fNameRequire ? "block" : "none"}>
                <RequiredTriangle />
                <span>Please input this field</span>
              </CustomValidationMessage>
            </Field>            
          </FormCol>
        </FormRow>

        <FormRow>
          <FormCol>
            <ControlLabel>Last Name</ControlLabel>
            <Field>
              <input
                type="text"
                className="form-control"
                placeholder="Please Enter Your Last Name"
                id="last_name"
                onChange={this.handleChange}
                pattern="[A-Za-z\s]+$"
                required
              />
              <CustomValidationMessage
                display={this.state.lNameRequire ? "block" : "none"}
              >
                <RequiredTriangle />
                <span>Please input this field</span>
              </CustomValidationMessage>
            </Field>            
          </FormCol>
        </FormRow>
        
        <FormRow>
          <FormCol>
            <ControlLabel>Email</ControlLabel>
            <Field>
              <input
                type="email"
                className="form-control"
                placeholder="Please Enter Your Email Address"
                id="email"
                onChange={this.handleChange}
                required
              />
              <CustomValidationMessage
                display={this.state.emailRequire ? "block" : "none"}
              >
                <RequiredTriangle />
                <span>Please input this field</span>
              </CustomValidationMessage>
            </Field>            
          </FormCol>
        </FormRow>

        <FormRow>
          <FormCol>
            <ControlLabel>Phone Number</ControlLabel>
            <Field>
              <input
                type="text"
                className="form-control"
                placeholder="Please Enter Your Phone Number"
                id="phone_no"
                onChange={this.handleChange}
                pattern="[0-9]{10}"
                required
              />
              <CustomValidationMessage
                display={this.state.phoneRequire ? "block" : "none"}
              >
                <RequiredTriangle />
                <span>Please input this field</span>
              </CustomValidationMessage>
            </Field>            
          </FormCol>
        </FormRow>
        
        <FormRow>
          <FormCol>
            <ControlLabel>ZipCode</ControlLabel>
            <Field>
              <input
                type="text"
                className="form-control"
                placeholder="Please Enter Your ZipCode"
                id="zipcode"
                onChange={this.handleChange}
                pattern="[0-9]+$"
                required
              />
              <CustomValidationMessage
                display={this.state.zipcodeRequire ? "block" : "none"}
              >
                <RequiredTriangle />
                <span>Please input this field</span>
              </CustomValidationMessage>
            </Field>            
          </FormCol>
        </FormRow>

        <FormRow>
          <FormCol>
            <ControlLabel>Message</ControlLabel>
            <Field>
              <textarea
                placeholder="Write your message here…"
                rows="3"
                id="comment"
                onChange={this.handleChange}
                required
                className="form-control"
              />
              <CustomValidationMessage
                display={this.state.commentRequire ? "block" : "none"}
              >
                <RequiredTriangle />
                <span>Please input this field</span>
              </CustomValidationMessage>
            </Field>            
          </FormCol>
        </FormRow>

        <FormRow>
          <FormCol>
          <Field>
            <Checkbox>
              <label>
                <input
                  className="form-control"
                  type="checkbox"
                  id="agreeViaSms"
                  name="agreeViaSms"
                  defaultChecked={true}
                  onChange={this.handleChange}
                  aria-label="contacted via SMS"
                />
                {` `}I agree that I will be contacted via SMS.
              </label>
            </Checkbox>
            <HelpText>By submitting this form, I agree to receive text messages from Probuilt Steel Buildings. Standard messaging rates and data charges may apply. Message frequency may vary. You can opt-out by replying STOP at any time or reply Help to get more information. </HelpText>
          </Field>
          </FormCol>
        </FormRow> 

        <ExploreMore>
          <button type="submit" form="rightform" className="btn-lg">
            <PrimaryButton text="Submit" />
          </button>
        </ExploreMore>
      </Form>
    )
  }
}

export default RightForm
